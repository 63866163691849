import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Typography,
} from '@mui/material';
import { arrayOf } from 'prop-types';
import { LeaderboardPropType } from '@inner-source/cis-shared/types';
import StarIcon from '@mui/icons-material/Star';

const LeaderboardList = ({ items }) => (
  <List>
    {items &&
      items.map((item) => (
        <ListItem key={item.id} button component={Link} href={item.profileUrl} target="_blank">
          <ListItemAvatar>
            <Avatar src={item.profileImageUrl} />
          </ListItemAvatar>
          <ListItemText primary={item.fullName} />
          <ListItemSecondaryAction>
            <div style={{ width: '45px', height: '45px', position: 'relative' }}>
              <StarIcon color="action" sx={{ color: '#ffce00', fontSize: 45 }} />
              <Typography
                variant="caption"
                sx={{ position: 'absolute', left: 'calc(50% - 0.6rem)', top: 'calc(50% - 0.5rem)' }}
              >
                <div style={{ width: '20px', height: '20px', textAlign: 'center' }}>{item.starsCount.toString()}</div>
              </Typography>
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
  </List>
);

LeaderboardList.propTypes = {
  items: arrayOf(LeaderboardPropType).isRequired,
};

export default LeaderboardList;
