/* eslint-disable no-console */
const defaultOptions = { digestErrors: false, defaultValue: undefined, exitIfNotSource: true };

const mappingRules = [
  {
    canRunThisRule: ([sourceMap, targetMap, condition]) =>
      sourceMap &&
      targetMap &&
      typeof sourceMap === 'string' &&
      typeof targetMap === 'string' &&
      ((condition && typeof condition === 'function') || !condition),
    run: ({ source, mapping, pojo, options }) => {
      const [sourceMap, targetMap] = mapping;
      const hasSourceMapField = Object.getOwnPropertyNames(source || {}).find((name) => name === sourceMap);
      if (!hasSourceMapField && !options.digestErrors) {
        const errorMsg = `DataMapper: No se encontro la propiedad ${sourceMap}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
      }
      return { ...pojo, [targetMap]: source && hasSourceMapField ? source[sourceMap] : options.defaultValue };
    },
  },
  {
    canRunThisRule: ([targetMap, lambda, condition]) =>
      targetMap &&
      lambda &&
      typeof targetMap === 'string' &&
      typeof lambda === 'function' &&
      ((condition && typeof condition === 'function') || !condition),
    run: async ({ source, mapping, pojo, options }) => {
      const [targetMap, lambda] = mapping;
      let lambdaResult = options.defaultValue;
      let runResult;
      try {
        const resolvedPojo = await pojo;
        lambdaResult = await lambda.call(resolvedPojo, source);
      } catch (error) {
        console.error(`dataMapper [${targetMap}]`, error);
        if (!options.digestErrors) {
          throw error;
        }
      } finally {
        runResult = { ...pojo, [targetMap]: lambdaResult };
      }
      return runResult;
    },
  },
];

const dataMapperReducer = (source, mappingDefinition, options) =>
  mappingDefinition.reduce(async (pojo, mapping) => {
    const selectedRule = mappingRules.find((rule) => rule.canRunThisRule(mapping));
    if (!selectedRule) {
      const message = 'No se encontro una rule que pueda mapear la definicion';
      if (!options.digestErrors) {
        throw new Error(message);
      }
      console.warn(message, mapping);
      return { ...(await pojo) };
    }
    const [, , condition] = mapping;
    if (condition && !condition(source)) {
      return { ...(await pojo) };
    }
    const merged = { ...(await pojo), ...(await selectedRule.run({ source, mapping, pojo, options })) };
    return merged;
  }, {});

const dataMapper = async (source, mappingDefinition, options = defaultOptions) => {
  const mergedOptions = { ...defaultOptions, ...options };
  const mutableArraySource = [].concat(source);
  if (!source && mergedOptions.exitIfNotSource) {
    return null;
  }
  const dataMapped = await Promise.all(
    mutableArraySource.map(async (sourceItem) => dataMapperReducer(sourceItem, mappingDefinition, mergedOptions)),
  );
  return Array.isArray(source) ? dataMapped : dataMapped[0];
};

export default dataMapper;
