import { httpPost } from '@inner-source/cis-shared/transports';

const getJiraData = async ({ label }) =>
  httpPost('https://mh2ghh9hdj.execute-api.us-east-1.amazonaws.com/Stage/get-jira-data', {
    // jiraUrl,
    // user,
    // apiToken,
    label,
  });

export default getJiraData;
