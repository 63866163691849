import React, { useState, useCallback } from 'react';
import { string, bool } from 'prop-types';
import { Box, List, ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const PortalNavMenuGroup = ({ label, fixed, closed, children }) => {
  const [open, setOpen] = useState(!closed);
  const handleOnClick = useCallback(() => {
    if (!fixed) {
      setOpen((value) => !value);
    }
  }, [fixed]);
  return (
    <Box sx={{ backgroundColor: 'primary.dark' }}>
      <ListItem disablePadding sx={{ py: 1, height: '56px' }}>
        <ListItemButton
          onClick={handleOnClick}
          disableRipple={fixed}
          disableTouchRipple={fixed}
          sx={{
            height: '56px',
            '&:hover': { cursor: fixed ? 'default' : 'pointer', backgroundColor: fixed ? 'initial' : '' },
          }}
        >
          <ListItemText primary={label} />
          {!fixed && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding dense>
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

PortalNavMenuGroup.propTypes = {
  fixed: bool,
  closed: bool,
  label: string.isRequired,
  children: ChildrenPropType,
};

export default PortalNavMenuGroup;
