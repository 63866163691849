import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Tab,
  List,
  Tabs,
  Box,
  Typography,
  Link,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import StarIcon from '@mui/icons-material/Star';
import { TabPanel } from '@inner-source/cis-shared/components';

import { LeaderboardTypeList } from '../../../../components';

const starmeupUrl = process.env.NX_STARMEUP_URL;

const Leaderboard = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '685px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
            <StarIcon />
          </Avatar>
        }
        title={<Typography variant="h6">Top Recognized Contributors</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
            <Tab label="Top Receivers" />
            <Tab label="Top Senders" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <List>
            <LeaderboardTypeList tabValue={tabValue} index={0} type="RECEIVER" />
          </List>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <List>
            <LeaderboardTypeList tabValue={tabValue} index={0} type="SENDER" />
          </List>
        </TabPanel>
      </CardContent>
      <CardActions sx={{ pl: 3, display: 'flex' }}>
        <Button
          variant="text"
          color="primary"
          component={Link}
          href={starmeupUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          See all
        </Button>
      </CardActions>
    </Card>
  );
};

export default Leaderboard;
