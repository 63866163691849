import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Avatar, CardContent, CardActions, Button, Typography, Link } from '@mui/material';
import { blue } from '@mui/material/colors';
import StorageIcon from '@mui/icons-material/Storage';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import { getActiveRepos } from '@inner-source/cis-shared/services';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';

import { RepoList } from '../../../../components';
import { TopActiveReposSkeleton } from './components';

const sourcegraphUrl = process.env.NX_SOURCEGRAPH_URL;

const TopActiveRepos = () => {
  const [repos, setRepos] = useState();
  const [doGetRepos, loading, error] = useAsyncFunc(
    useCallback(async () => {
      if (!repos) {
        const response = await getActiveRepos({ limit: 6 });
        // const response = await getProjectsWithActiveIsssues({ limit: 5 });
        setRepos(response && response.repositories);
      }
    }, [repos]),
  );

  useEffect(() => {
    doGetRepos();
  }, [doGetRepos]);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
            <StorageIcon />
          </Avatar>
        }
        title={<Typography variant="h6">Top Active Source Code Repos</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        {error && <ErrorMessage onRetry={doGetRepos} />}
        {!error && loading && <TopActiveReposSkeleton />}
        {!error && !loading && repos && repos.length === 0 && <NoData />}
        {!error && !loading && repos && repos.length > 0 && <RepoList repos={repos} />}
      </CardContent>
      <CardActions sx={{ pl: 3, display: 'flex' }}>
        <Button
          variant="text"
          color="primary"
          component={Link}
          href={sourcegraphUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          See all
        </Button>
      </CardActions>
    </Card>
  );
};

export default TopActiveRepos;
