import React, { useCallback } from 'react';
import { CssBaseline, Slide } from '@mui/material';
import { HourglassFull } from '@mui/icons-material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, SecurityProvider, EnvProvider, AppBootstrapper } from '@inner-source/cis-shared/components';
import { SecureRoute } from '@okta/okta-react';
import { SnackbarProvider } from 'notistack';
import { initializeHttp, initializeAuthUser } from './bootstraps';
import { Home, Projects } from './modules';
import { PortalLayout } from './components';

const clientId = process.env.NX_OKTA_CLIENT_ID;
const issuer = process.env.NX_OKTA_ISSUER;
const pkce = Boolean(process.env.NX_OKTA_PKCE && process.env.NX_OKTA_PKCE === 'true');
const apiBaseUrl = process.env.NX_API_BASE_URL;

const App = () => {
  const renderLogo = useCallback(() => <HourglassFull sx={{ width: '56px', height: '56px' }} />, []);
  return (
    <>
      <CssBaseline />
      <Router>
        <SecurityProvider oidc={{ clientId, issuer, pkce }}>
          <EnvProvider env={{ apiBaseUrl }}>
            <AppBootstrapper Logo={renderLogo} serial={[initializeAuthUser, initializeHttp]}>
              <ThemeProvider>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  TransitionComponent={Slide}
                >
                  <SecureRoute path="/">
                    <PortalLayout>
                      <Switch>
                        <Route exact path="/projects">
                          <Projects />
                        </Route>
                        <Route>
                          <Home />
                        </Route>
                      </Switch>
                    </PortalLayout>
                  </SecureRoute>
                </SnackbarProvider>
              </ThemeProvider>
            </AppBootstrapper>
          </EnvProvider>
        </SecurityProvider>
      </Router>
    </>
  );
};

export default App;
