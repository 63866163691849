import { DateTime } from 'luxon';
import { string, func, shape } from 'prop-types';

const FormatDate = ({
  children,
  date,
  inputFormat = `yyyy-MM-dd'T'HH:mm:ss.SSSZZZ`,
  format = DateTime.DATE_SHORT,
  defaultValue = '-',
}) => {
  const dateTime = DateTime.fromFormat(date || '', inputFormat);
  const formattedDateTime = (!dateTime.invalid && dateTime.toLocaleString(format)) || defaultValue;

  if (!children) {
    return formattedDateTime;
  }
  return children(formattedDateTime);
};

FormatDate.propTypes = {
  date: string,
  inputFormat: string,
  format: shape({}),
  defaultValue: string,
  children: func,
};

export default FormatDate;
