const getLastTimeServiceRan = async (db, service) => {
  const params = { TableName: 'cis-last-time-services-ran', Key: { service } };
  const result = await db.get(params).promise();
  if (result && result.Item) {
    return result.Item.lastTime;
  }
  return null;
};

export default getLastTimeServiceRan;
