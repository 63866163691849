import React from 'react';
import { object } from 'prop-types';
import { deepmerge } from '@mui/utils';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const defaultTheme = {
  palette: {
    mode: 'light',
  },
};

const ThemeProvider = ({ children, theme }) => {
  const theTheme = createTheme(deepmerge(defaultTheme, theme));
  return <MuiThemeProvider theme={theTheme}>{children}</MuiThemeProvider>;
};

ThemeProvider.propTypes = {
  children: ChildrenPropType,
  // eslint-disable-next-line react/forbid-prop-types
  theme: object,
};

export default ThemeProvider;
