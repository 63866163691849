import axios from 'axios';

let httpConfig = null;

const http = axios.create();
const cancelToken = axios.CancelToken;

export const basicAuthStrategy = (token) => () => `Basic ${token || httpConfig?.basicToken || ''}`;

export const bearerAuthStrategy = (token) => () => `Bearer ${token}`;

export const customAuthStrategy = (type, token) => () => `${type} ${token}`;

export const getAuthStrategy = () => httpConfig.authStrategy;

export const setAuthStrategy = (strategy) => {
  httpConfig = { ...httpConfig, authStrategy: strategy };
};

http.interceptors.request.use((config) => {
  if (!httpConfig) {
    throw new Error('Http transport not initialized');
  }
  const { authStrategy, basicToken, baseUrl, env } = httpConfig;

  const { useAuth = null } = config;
  const auth = useAuth || authStrategy || basicAuthStrategy(basicToken);
  const baseURL = env === 'local' ? null : baseUrl;

  return {
    ...config,
    baseURL,
    headers: {
      ...config.headers,
      Authorization: auth(),
    },
  };
});

export const getUrlWithParams = (url, params) => {
  let formattedUrl = url;
  const paramsInUrl = url.match(/(:\w+)/g);
  if (!paramsInUrl) {
    return formattedUrl;
  }
  Object.values(paramsInUrl).forEach((param) => {
    formattedUrl = formattedUrl.replace(param, params[param.substr(1)] || 'undefined');
  });

  return formattedUrl;
};

export const getCancelToken = () => {
  const source = cancelToken.source();
  return [source.token, source.cancel];
};

export const httpTransportInit = (options) => {
  httpConfig = { ...options };
};

export default http;
