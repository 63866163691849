/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Avatar, CardContent, CardActions, Button, Typography, TableContainer, TableHead, Table, TableBody, Box, Snackbar, Slide, TableRow, TableCell, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';

const SourcegraphComponent = () => {




  useEffect(() => {

  }, []);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '250px', borderRadius: '16px' }}>
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>

        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src="/assets/images/sourcegraph.png" alt="SourceGraph" />
        </div>
        <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
          <h3>Sourcegraph</h3>
        </div>
        <div style={{ fontSize: "12px" }}>
          Sourcegraph is a code intelligence platform that helps devs answer questions in their code by searching and understanding across their organization's codebase.
        </div>

      </CardContent>

    </Card>
  );
};

export default SourcegraphComponent;
