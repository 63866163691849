import React, { useRef, useCallback } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { shape, string, arrayOf, bool } from 'prop-types';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { SecurityRender } from './components';

const SecurityProvider = ({ loginCallbackUri = '/login/callback', oidc, children }) => {
  const options = {
    pkce: true,
    scopes: ['openid', 'profile', 'email'],
    redirectUri: `${window.location.origin}${loginCallbackUri}`,
    ...oidc,
  };
  const history = useHistory();
  const oktaAuth = useRef(new OktaAuth(options));

  const restoreOriginalUri = useCallback(
    (currentOktaAuth, originalUri) => {
      const url = toRelativeUrl(originalUri || '/', window.location.origin);
      history.replace(url);
    },
    [history],
  );

  return (
    <Security oktaAuth={oktaAuth.current} restoreOriginalUri={restoreOriginalUri}>
      <Route exact path={loginCallbackUri} component={LoginCallback} />
      <SecurityRender>{children}</SecurityRender>
    </Security>
  );
};

SecurityProvider.propTypes = {
  loginCallbackUri: string,
  oidc: shape({
    clientId: string.isRequired,
    issuer: string.isRequired,
    redirectUri: string,
    scopes: arrayOf(string),
    pkce: bool,
    disableHttpsCheck: bool,
  }),
  children: ChildrenPropType,
};

export default SecurityProvider;
