import React from 'react';
import { func } from 'prop-types';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const IssuePanelHeader = ({ onClose }) => (
  <AppBar position="relative" sx={{ bgcolor: 'background.default' }}>
    <Toolbar>
      <IconButton onClick={onClose} sx={{ color: 'white' }}>
        <ArrowForward />
      </IconButton>
      <Typography variant="h6" sx={{ color: 'white' }}>
        Project details
      </Typography>
    </Toolbar>
  </AppBar>
);

IssuePanelHeader.propTypes = {
  onClose: func.isRequired,
};

export default IssuePanelHeader;
