import React, { useCallback, useState, useEffect } from 'react';
import { arrayOf, bool, oneOfType, oneOf, any, func } from 'prop-types';
import { TableContainer, TableHead, Table, TableBody, Box, Snackbar, Slide } from '@mui/material';
import { IssuePropType, Constants } from '@inner-source/cis-shared/types';
import { postApplicant, sendEmail } from '@inner-source/cis-shared/services';
import { useAsyncFunc, useEnv } from '@inner-source/cis-shared/hooks';
import { IssueTableHead, IssueTableRow, IssueTableSkeleton, ContributorSendStarDialog, IssuePanel } from './components';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IssueTable = ({ issues, loading, variant, onChange }) => {
  const [contributeNotif, setContributeNotif] = React.useState(false);
  const [contributeNotifMsg, setContributeNotifMsg] = React.useState('');
  const [contributeNotifTime, setContributeNotifTime] = React.useState(10000);
  const [contributeNotifSeverity, setContributeNotifSeverity] = React.useState('success');

  const [currentActionIssueId, setCurrentActionIssueId] = useState();
  const [sendStarData, setSendStarData] = useState();
  const [issueInfo, setIssueInfo] = useState();
  const { user } = useEnv();
  const [doPostApplicant, runPostApplicant, errorPostApplicant] = useAsyncFunc(postApplicant);
  const [doSendEmail, runSendEmail, errorSendEmail] = useAsyncFunc(sendEmail);

  function findObjectById(data, id) {
    // Use the find method to find the first object where the 'id' property matches
    return data.find((item) => item.id === id);
  }

  const handleOnContribute = useCallback(
    async ({ id }) => {
      setCurrentActionIssueId(id);
      const { email, name } = user;
      const userName = name || 'Name not found';
      console.log('issues', issues);
      const issue = findObjectById(issues, id);
      console.log('issueInfo', issue);
      const Projectname = issue.summary ? issue.summary : 'Project Name not found!';
      const ProjectOwner = issue['Project Owner Email']
        ? issue['Project Owner Email']
        : 'admin.innersource@carrier.com';
      const FormLink = issue['Form Link'] ? issue['Form Link'] : 'Form Link not found!';
      const ProjectID = issue.key ? issue.key : 'Project ID not found!';

      const updatedIssue = await doPostApplicant({ id, applicant: { email, name } });
      if (updatedIssue) {
        setContributeNotifMsg(
          'Thank you for applying to the InnerSource Project! The project owner will be reaching out to you soon to discuss your application.',
        );
        setContributeNotifSeverity('success');
        setContributeNotifTime(10000);
        setContributeNotif(true);
        onChange(updatedIssue);
        const sendingEmail = await doSendEmail({
          toAddress: [ProjectOwner],
          fromAddress: ['no-reply.innersource@carrier.com'],
          ccAddress: [email, 'admin.innersource@carrier.com'],
          name: userName,
          issue: Projectname,
          id: ProjectID,
          formLink: FormLink,
        });
        console.log(sendingEmail);
      }
    },
    [user, doPostApplicant, issues, onChange, doSendEmail],
  );

  useEffect(() => {
    if (errorPostApplicant && errorPostApplicant.response.data.errorMessage) {
      setContributeNotifMsg(errorPostApplicant.response.data.errorMessage);
      setContributeNotifSeverity('warning');
      setContributeNotifTime(10000);
      setContributeNotif(true);
    }
  }, [errorPostApplicant]);

  const handleNotifClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setContributeNotif(false);
  };

  const handleOnSayThanks = useCallback(({ contributor, issue }) => {
    setSendStarData({ contributor, issue });
  }, []);

  const handleOnCloseContributorSendStarDialog = useCallback(() => {
    setSendStarData(null);
  }, []);

  const handleOnSendStar = useCallback(
    ({ result }) => {
      setStarUrl(starMeUpUrl + '/me/activity/' + result.starId);
      onChange(result.issue);
    },
    [onChange],
  );

  const handleOnClick = useCallback(({ issue, contributor }) => {
    setIssueInfo({ issue, contributor });
  }, []);

  const handleOnClose = useCallback(() => {
    setIssueInfo(null);
  }, []);

  return (
    <TableContainer component={Box}>
      <Snackbar
        open={contributeNotif}
        autoHideDuration={contributeNotifTime}
        onClose={handleNotifClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert severity={contributeNotifSeverity} sx={{ width: '100%' }}>
          {contributeNotifMsg}
        </Alert>
      </Snackbar>
      {loading && <IssueTableSkeleton />}
      {!loading && issues && issues.length > 0 && (
        <>
          <IssuePanel
            issue={issueInfo && issueInfo.issue}
            contributor={issueInfo && issueInfo.contributor}
            variant={variant}
            onClose={handleOnClose}
          />
          <ContributorSendStarDialog
            contributor={sendStarData && sendStarData.contributor}
            issue={sendStarData && sendStarData.issue}
            onClose={handleOnCloseContributorSendStarDialog}
            onSendStar={handleOnSendStar}
          />
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <IssueTableHead variant={variant} />
            </TableHead>
            <TableBody>
              {variant === Constants.ISSUE_STATE_ACTIVE &&
                issues &&
                issues.map((issue) => (
                  <IssueTableRow
                    key={issue.id}
                    issue={issue}
                    loading={issue.id === currentActionIssueId && runPostApplicant}
                    canContribute={
                      variant === Constants.ISSUE_STATE_ACTIVE &&
                      ![].concat(issue.applicants || []).find(({ email }) => email === user.email)
                    }
                    variant={variant}
                    onClick={handleOnClick}
                    onContribute={handleOnContribute}
                    onSayThanks={handleOnSayThanks}
                  />
                ))}
              {variant === Constants.ISSUE_STATE_DONE &&
                issues &&
                issues.map(({ contributors = [], usersWhoSubmittedStars = [], ...issue }) =>
                  contributors.map((contributor) => (
                    <IssueTableRow
                      key={`${issue.id}-${contributor.email}`}
                      issue={issue}
                      contributor={contributor}
                      loading={issue.id === currentActionIssueId && runPostApplicant}
                      canSayThanks={
                        contributor.email.toLowerCase() !== user.email.toLowerCase() &&
                        !usersWhoSubmittedStars.find(
                          ({ from, to }) =>
                            from.toLowerCase() === user.email.toLowerCase() &&
                            to.toLowerCase() === contributor.email.toLowerCase(),
                        )
                      }
                      variant={variant}
                      onClick={handleOnClick}
                      onContribute={handleOnContribute}
                      onSayThanks={handleOnSayThanks}
                    />
                  )),
                )}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};

IssueTable.propTypes = {
  issues: oneOfType([arrayOf(IssuePropType), any]),
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]).isRequired,
  loading: bool,
  onChange: func.isRequired,
};

export default IssueTable;
