import React from 'react';
import { string } from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const NoData = ({
  primary = 'Nothing to see here',
  secondary = 'There are no records to show you right now, there may be no entries or you filters may be too tightly defined.',
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
      }}
    >
      <InfoOutlined sx={{ width: '80px', height: '80px', color: theme.palette.text.disabled }} />
      <Typography variant="h4" sx={{ p: 2 }}>
        {primary}
      </Typography>
      <Typography variant="body1" sx={{ p: 1, color: theme.palette.text.disabled }}>
        {secondary}
      </Typography>
    </Box>
  );
};

NoData.propTypes = {
  primary: string,
  secondary: string,
};

export default NoData;
