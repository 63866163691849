/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Avatar, CardContent, CardActions, Button, Typography, TableContainer, TableHead, Table, TableBody, Box, Snackbar, Slide, TableRow, TableCell, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import StorageIcon from '@mui/icons-material/Storage';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';

import { ProjectList } from '../../../../components';

const SourceReposToDisplay = () => {
  const [moduleData, setModuleData] = useState([]);

  const doGetData = () => {
    fetch('assets/sourcegraphdata.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) =>{
      return response.json();
    }
    ).then((data) => {
      console.log(data);
      setModuleData(data);
    });
  }

  useEffect(() => {
    doGetData();
  }, []);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '685px', borderRadius: '16px' }}>
      <CardHeader
        avatar={
          <Avatar src="/assets/images/Reusable_Icon.png">
           
          </Avatar>
        }
        title={<Typography variant="h6">SW Reusable Modules</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow sx={{backgroundColor:'#EEF7FF'}}>
                <TableCell>Module Name</TableCell>
                <TableCell>Technology</TableCell>
                <TableCell>Short Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {moduleData &&
                moduleData.map((repoData) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">
                        <a href={repoData.url} target="_blank" rel="noopener noreferrer">{repoData.modulename}</a>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {repoData.technology}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {repoData.shortdesc}
                      </Typography>
                      <a href={repoData.url + "/-/blob/README.md"} target="_blank" rel="noopener noreferrer">more..</a>
                    </TableCell>
                    
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        
      </CardContent>

    </Card>
  );
};

export default SourceReposToDisplay;
