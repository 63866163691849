import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Tab,
  List,
  Tabs,
  Box,
  Typography,
  Link,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import SchoolIcon from '@mui/icons-material/School';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';
import { LearningList } from '../../../../components';
import { LearningSkeleton } from './components';
import { getPagesByLabel } from '@inner-source/cis-shared/services';

const confluenceUrl = process.env.NX_CONFLUENCE_URL + '/learning';

const Learning = () => {
  const [pages, setPages] = useState();

  const [doGetPages, loading, error] = useAsyncFunc(
    
    useCallback(async () => {
      if (!pages) {
        const response = await getPagesByLabel({ label: "learning", limit: 5 });
        setPages(response && response.pages);
      }
    }, [pages]),
  );

  useEffect(() => {
    doGetPages();
  }, [doGetPages]);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
            <SchoolIcon />
          </Avatar>
        }
        title={<Typography variant="h6">Learning Center</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1 }}>
          {error && <ErrorMessage onRetry={doGetPages} />}
          {!error && loading && <LearningSkeleton />}
          {!error && !loading && pages && pages.length === 0 && <NoData />}
          {!error && !loading && pages && pages.length > 0 && <LearningList pages={pages} />}
      </CardContent>
      <Divider />
      <CardActions sx={{ pl: 3, display: 'flex' }}>
        <Button variant="text" color="primary" component={Link} href={confluenceUrl} target="_blank">
          See all
        </Button>
      </CardActions>
    </Card>
  );
};

export default Learning;
