import React, { useCallback, useState } from 'react';
import { List } from '@mui/material';
import { arrayOf, oneOf, bool, func } from 'prop-types';
import { ProjectPropType, Constants } from '@inner-source/cis-shared/types';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';
import { ProjectListItem, ProjectListSkeleton } from './components';

const ProjectList = ({ projects, loading, error, variant = Constants.ISSUE_STATE_ACTIVE, onRetry }) => {
  const [expanded, setExpanded] = useState();

  const handleOnClick = useCallback((event, { id }) => {
    setExpanded((value) => {
      if (value === id) {
        return null;
      }
      return id;
    });
  }, []);

  return (
    <>
      {error && <ErrorMessage onRetry={onRetry} />}
      {!error && loading && <ProjectListSkeleton />}
      {!error && !loading && projects && projects.length === 0 && <NoData />}
      {!error && !loading && projects && projects.length > 0 && (
        <List>
          {projects &&
            projects.map((project) => (
              <ProjectListItem
                key={project.id}
                open={expanded === project.id}
                project={project}
                variant={variant}
                onClick={handleOnClick}
              />
            ))}
        </List>
      )}
    </>
  );
};

ProjectList.propTypes = {
  projects: arrayOf(ProjectPropType),
  loading: bool,
  error: bool,
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]),
  onRetry: func.isRequired,
};

export default ProjectList;
