import React, { useCallback } from 'react';
import { elementType, string } from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemButton, ListItemText, ListItemAvatar, Avatar, Link as MuiLink } from '@mui/material';
import { useEnv } from '@inner-source/cis-shared/hooks';

const PortalNavMenuItem = ({ Icon, label, ...props }) => {
  const { isMenuOpen } = useEnv();
  const { to, href, iconurl } = props;
  const { pathname } = useLocation();
  const isSelected = useCallback(
    (path) => pathname === path || (pathname === '/login/callback' && path === '/'),
    [pathname],
  );
  return (
    <ListItem
      disablePadding
      component={(to && RouterLink) || (href && MuiLink)}
      selected={isSelected(to)}
      sx={{
        color: 'black',
        '&.Mui-selected': {
          backgroundColor: 'primary.light',
          color: 'white'
        },
        '&:hover':{
          backgroundColor: 'lightblue',
        },

        borderRadius: '8px',
        margin: '20px',
        width: `${isMenuOpen ? '90%' : '60px'}`,
        height: '60px'
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <ListItemButton>
        <ListItemAvatar>
          <Avatar src={iconurl} sx={{ width: 24, height: 24 }} variant="square">
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} hidden={!isMenuOpen}/>
      </ListItemButton>
    </ListItem>
  );
};

PortalNavMenuItem.propTypes = {
  Icon: elementType,
  label: string.isRequired,
  to: string,
  href: string,
  iconurl: string
};

export default PortalNavMenuItem;
