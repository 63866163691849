import React, { useReducer, useMemo, useCallback, useEffect } from 'react';
import { string, shape } from 'prop-types';
import { ChildrenPropType, EnvContext } from '@inner-source/cis-shared/types';
import { useMediaQuery, useTheme } from '@mui/material';

const SET_IS_MENU_OPEN_ACTION = 'SET_IS_MENU_OPEN_ACTION';
const SET_USER_ACTION = 'SET_USER_ACTION';

const initialState = {
  isMenuOpen: false,
  user: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_IS_MENU_OPEN_ACTION:
      return { ...state, isMenuOpen: payload };
    case SET_USER_ACTION:
      return { ...state, user: payload };
    default:
      return state;
  }
};

const EnvProvider = ({ children, env, navSize = '300px' }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [state, dispatch] = useReducer(reducer, initialState, (currentState) => ({
    ...currentState,
    isMenuOpen: false,
  }));

  const getEnv = useCallback(
    (name, defaultValue) => {
      if (!env || !Object.getOwnPropertyNames(env).find((propName) => propName === name)) {
        return defaultValue;
      }
      return env && env[name];
    },
    [env],
  );

  const setCurrentUser = useCallback((user) => {
    dispatch({ type: SET_USER_ACTION, payload: user });
  }, []);

  const toggleMenu = useCallback(() => {
    const { isMenuOpen } = state;
    dispatch({ type: SET_IS_MENU_OPEN_ACTION, payload: !isMenuOpen });
  }, [state]);

  const value = useMemo(() => {
    const { isMenuOpen, user } = state;
    return {
      isMobileView,
      isMenuOpen,
      navSize,
      user,
      toggleMenu,
      getEnv,
      setCurrentUser,
    };
  }, [isMobileView, state, navSize, toggleMenu, getEnv, setCurrentUser]);

  useEffect(() => {
    const { isMenuOpen } = state;
    if (isMobileView && isMenuOpen) {
      toggleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  useEffect(() => {
    const { isMenuOpen } = state;
    if (!isMobileView && !isMenuOpen) {
      //toggleMenu(); by default menu should be hidden
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
};

EnvProvider.propTypes = {
  navSize: string,
  env: shape({ apiBaseUrl: string }),
  children: ChildrenPropType,
};

export default EnvProvider;
