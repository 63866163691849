import React from 'react';
import { number } from 'prop-types';
import { Skeleton, Box } from '@mui/material';

const IssueTableSkeleton = ({ rows = 3 }) => (
  <Box sx={{ width: '100%' }}>
    {[...Array(rows).keys()].map((key) => (
      <Skeleton key={key} animation="wave" />
    ))}
  </Box>
);

IssueTableSkeleton.propTypes = {
  rows: number,
};

export default IssueTableSkeleton;
