import React, { useCallback } from 'react';
import { oneOf, func, bool } from 'prop-types';
import { TableRow, TableCell, Avatar, Typography, Link, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IssuePropType, ContributorPropType, Constants } from '@inner-source/cis-shared/types';
import { FormatDate } from '@inner-source/cis-shared/components';

const IssueTableRow = ({
  issue,
  contributor,
  variant,
  loading,
  canContribute,
  canSayThanks,
  onClick,
  onContribute,
  onSayThanks,
}) => {
  const handleOnContribute = useCallback(() => {
    onContribute(issue);
  }, [issue, onContribute]);

  const handleOnSayThanks = useCallback(() => {
    onSayThanks({ contributor, issue });
  }, [issue, contributor, onSayThanks]);

  const handleOnClick = useCallback(() => {
    onClick({ issue, contributor });
  }, [issue, contributor, onClick]);

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={issue.type.name}>
          <Avatar src={issue.type.iconUrl} sx={{ width: '24px', height: '24px' }} />
        </Tooltip>
      </TableCell>
      <TableCell sx={{ maxWidth: '300px' }}>
        <Typography
          noWrap
          variant="body2"
          component={Link}
          onClick={handleOnClick}
          sx={{ display: 'block', cursor: 'pointer' }}
        >
          {issue.summary}
        </Typography>
      </TableCell>
      {variant === Constants.ISSUE_STATE_DONE && (
        <TableCell sx={{ maxWidth: '150px' }}>
          <Typography noWrap variant="body2">
            {variant === Constants.ISSUE_STATE_DONE && contributor && contributor.name}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <FormatDate date={issue.created}>
          {(date) => (
            <Typography noWrap variant="body2">
              {date}
            </Typography>
          )}
        </FormatDate>
      </TableCell>
      <TableCell>
        <Typography
          noWrap
          variant="body2"
          component={Link}
          href={issue['Sourcegraph URL']}
          target="_blank"
          sx={{ display: 'block', cursor: 'pointer' }}
        >
          View Source Code
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          noWrap
          variant="body2"
          component={Link}
          href={issue['Contribution Guide']}
          target="_blank"
          sx={{ display: 'block', cursor: 'pointer' }}
        >
          View Contribution Guide
        </Typography>
      </TableCell>
      <TableCell>
        {variant === Constants.ISSUE_STATE_ACTIVE && (
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
            disabled={!canContribute}
            onClick={handleOnContribute}
          >
            Apply
          </LoadingButton>
        )}
        {variant === Constants.ISSUE_STATE_DONE && (
          <LoadingButton
            variant="contained"
            color="success"
            size="small"
            loading={loading}
            disabled={!canSayThanks}
            onClick={handleOnSayThanks}
          >
            Say Thanks!
          </LoadingButton>
        )}
      </TableCell>
    </TableRow>
  );
};

IssueTableRow.propTypes = {
  issue: IssuePropType.isRequired,
  contributor: ContributorPropType,
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]).isRequired,
  loading: bool,
  canContribute: bool,
  canSayThanks: bool,
  onClick: func.isRequired,
  onContribute: func.isRequired,
  onSayThanks: func,
};

export default IssueTableRow;
