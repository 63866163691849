const setLastTimeServiceRan = async (db, service, lastTime) => {
  const params = { TableName: 'cis-last-time-services-ran' };
  const result = await db.get({ ...params, Key: { service } }).promise();
  if (result && result.Item) {
    await db
      .update({
        ...params,
        Key: { service },
        UpdateExpression: 'set lastTime = :lastTime',
        ExpressionAttributeValues: {
          ':lastTime': lastTime,
        },
      })
      .promise();
  } else {
    await db
      .put({
        ...params,
        Item: { service, lastTime },
      })
      .promise();
  }
};

export default setLastTimeServiceRan;
