/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Avatar, CardContent, CardActions, Button, Typography, TableContainer, TableHead, Table, TableBody, Box, Snackbar, Slide, TableRow, TableCell, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';

import { ProjectList } from '../../../../components';

const ActiveProjectsToDisplay = () => {




  useEffect(() => {

  }, []);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '685px',  borderRadius: '16px' }}>
      <CardHeader
        avatar={
          <Avatar src="/assets/images/Projects_Icon.png">
           
          </Avatar>
        }
        title={<Typography variant="h6">Active InnerSource Projects</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>

        <div>
          <img src="/assets/images/staf.png" alt="STAF Project" />
        </div>
        <div style={{fontSize: "14px"}}>
          <b>STAF</b>(Software Test Automation Framework) is a Python based test automation framework, that can be adopted by BU programs make the automation efforts more efficient and productive.
          It supports more than 25 modules that helps to support every component of testing. The STAF is continuously evolving by integrations with Git, Jira and new frameworks for API testing which makes it more powerful.
          The <b>Innersource platform</b> is helping development community to actively contribute <b>to further enhance STAF</b>.
          As a part of this features such as integration of AI/ML algorithms, Elastic stack are planned to derive better productivity and increased quality.
        
          If you have any project that can be innersourced or you want to contribute,
          Pls reach out to Innersource team
        </div>

      </CardContent>

    </Card>
  );
};

export default ActiveProjectsToDisplay;
