import React from 'react';
import { List } from '@mui/material';
import { arrayOf } from 'prop-types';
import { RepoPropType } from '@inner-source/cis-shared/types';
import { RepoListItem } from './components';

const RepoList = ({ repos }) => <List>{repos && repos.map((repo) => <RepoListItem key={repo.id} repo={repo} />)}</List>;

RepoList.propTypes = {
  repos: arrayOf(RepoPropType).isRequired,
};

export default RepoList;
