import { HttpError } from '@inner-source/cis-shared/types';
import Logger from './logger';

const handleError = (errorInstance, unhandledErrorCallback) => {
  Logger.error('Error', errorInstance);

  if (errorInstance instanceof HttpError) {
    return errorInstance.toResponse();
  }
  return (
    (unhandledErrorCallback && unhandledErrorCallback()) || {
      statusCode: 400,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ errorMessage: errorInstance.message }),
    }
  );
};

export default handleError;
