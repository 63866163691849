import React from 'react';
import { Box, useTheme } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { useEnv } from '@inner-source/cis-shared/hooks';

const AppContent = ({ children }) => {
  const { isMenuOpen, navSize } = useEnv();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 100%',
        ml: isMenuOpen ? navSize : '100px',
        pl: 4,
        pr: 4,
        pt: 2,
        pb: 2,
        bgcolor: theme.palette.grey[100],
      }}
    >
      {children}
    </Box>
  );
};

AppContent.propTypes = {
  children: ChildrenPropType,
};

export default AppContent;
