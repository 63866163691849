import { useState, useCallback, useRef, useEffect } from 'react';

const useAsyncFunc = (func) => {
  const isUnmounted = useRef(false);
  const [state, setState] = useState({ running: false, error: null });
  const setStateIfLoaded = useCallback((newState) => {
    if (!isUnmounted.current) {
      setState(newState);
    }
  }, []);
  const wrappedFunc = useCallback(
    async (...args) => {
      let result;
      try {
        setStateIfLoaded({ ...state, running: true, error: null });
        result = await func(...args);
        setStateIfLoaded({ ...state, running: false, error: null });
      } catch (error) {
        setStateIfLoaded({ ...state, error, running: false });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [func, setStateIfLoaded],
  );

  const clear = useCallback(() => {
    setState({ running: false, error: null });
  }, []);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );
  return [wrappedFunc, state.running, state.error, clear];
};

export default useAsyncFunc;
