import React from 'react';
import { string, func } from 'prop-types';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const ErrorMessage = ({
  primary = 'Something went wrong...',
  secondary = 'We are going to fix it. Try later.',
  onRetry,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
      }}
    >
      <ErrorOutline sx={{ width: '80px', height: '80px', color: theme.palette.error.main }} />
      <Typography variant="h4" sx={{ p: 2 }}>
        {primary}
      </Typography>
      <Typography variant="h6" sx={{ p: 1, color: theme.palette.text.disabled }}>
        {secondary}
      </Typography>
      {onRetry && <Button onClick={onRetry}>Retry</Button>}
    </Box>
  );
};

ErrorMessage.propTypes = {
  primary: string,
  secondary: string,
  onRetry: func,
};

export default ErrorMessage;
