import React from 'react';
import { number } from 'prop-types';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const TabPanel = ({ value, index, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {children}
  </div>
);

TabPanel.propTypes = {
  value: number.isRequired,
  index: number.isRequired,
  children: ChildrenPropType,
};

export default TabPanel;
