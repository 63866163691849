import React, { useCallback, useState, useEffect } from 'react';
import { List, TabPanel } from '@mui/material';
import { LeaderboardList } from './components';
import { LeaderboardSkeleton } from './components';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import { getLeaderboard } from '@inner-source/cis-shared/services';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';

const LeaderboardTypeList = ({ type }) => {
  const [leaderboard, setLeaderboard] = useState();

  const [doGetLeaderboard, loading, error] = useAsyncFunc(
    useCallback(async () => {
      if (!leaderboard) {
        const response = await getLeaderboard({ typeId: type });
        setLeaderboard(response && response.users);
      }
    }, [leaderboard]),
  );

  useEffect(() => {
    doGetLeaderboard();
  }, [doGetLeaderboard]);

  return (
    <div>
      {error && <ErrorMessage onRetry={doGetLeaderboard} />}
      {!error && loading && <LeaderboardSkeleton />}
      {!error && !loading && leaderboard && leaderboard.length === 0 && <NoData />}
      {!error && !loading && leaderboard && leaderboard.length > 0 && <LeaderboardList items={leaderboard} />}
    </div>
  )
};

export default LeaderboardTypeList;
