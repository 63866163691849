class HttpError extends Error {
  constructor(message, statusCode = 500) {
    super(message);
    this.statusCode = statusCode;
  }

  toResponse() {
    return {
      statusCode: this.statusCode,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ errorMessage: this.message, errorType: 'HTTPError' }),
    };
  }
}

export default HttpError;
