import React from 'react';
import { string } from 'prop-types';
import { Drawer } from '@mui/material';
import { useEnv } from '@inner-source/cis-shared/hooks';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { AppNavHeader } from './components';
import ThemeProvider from '../theme-provider';

const AppNav = ({ title, to, children }) => {
  const { isMobileView, isMenuOpen, navSize, toggleMenu } = useEnv();
  return (
    <ThemeProvider
      theme={{
        palette: {
          mode: 'dark',
          primary: {
            light: '#188AED',
            main: '#172335',
            dark: '#283270',
          },
          background: { paper: 'white' },
        },
      }}
    >
      <Drawer
        variant={(isMobileView && 'temporary') || (!isMobileView && !isMenuOpen && 'persistent') || 'permanent'}
        open={true}
        sx={{
          width: `${isMenuOpen ? navSize : '100px'}`,

          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: `${isMenuOpen ? navSize : '100px'}`,
            boxSizing: 'border-box',
            top: '65px',
            height: '80%'
          },
          backgroundColor: 'white',


        }}
        onClose={toggleMenu}
      >
        <div id='divDrawer' style={{ height: '100%', width:'100%', overflow:'hidden' }} onMouseEnter={toggleMenu} onMouseLeave={toggleMenu}>
          {children}
          </div>
      </Drawer>
    </ThemeProvider>
  );
};

AppNav.propTypes = {
  title: string.isRequired,
  to: string,
  children: ChildrenPropType,
};

export default AppNav;
