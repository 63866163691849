import React from 'react';
import { string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const AppTitle = ({ to = '/', children }) => (
  <Link underline="none" component={RouterLink} to={to} color="inherit">
    <img src="/assets/images/Carrier1x.png"></img>
    <Typography variant="h6">{children}</Typography>
  </Link>
);


AppTitle.propTypes = {
  children: ChildrenPropType,
  to: string,
};

export default AppTitle;
