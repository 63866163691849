import { useContext } from 'react';
import { EnvContext } from '@inner-source/cis-shared/types';

const useEnv = () => {
  const context = useContext(EnvContext);
  if (!context) {
    throw Error('You must initialize EnvProvider before use it!');
  }
  return context;
};

export default useEnv;
