import React, { useState, useCallback } from 'react';
import { string, func } from 'prop-types';
import ExitToApp from '@mui/icons-material/ExitToApp';
import {
  IconButton,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Button,
} from '@mui/material';
import { stringToAvatar } from '@inner-source/cis-shared/helpers';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const AppUserMenu = ({ fullName, children, onCloseSession }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button color="inherit" onClick={handleOnClick}>
        {/* <Avatar
          imgProps={{ width: '16px', height: '17px', padding: '1px' }}
          sx={{
            width: '24px',
            height: '24px',
            marginLeft: '10px',
            color: '#8f8fae',
            backgroundColor: '#283270',
            padding: '1px',
          }}
          src="/assets/images/HelpIcon.png"
        ></Avatar>
        <Avatar
          imgProps={{ width: '16px', height: '18px', padding: '1px' }}
          sx={{
            width: '24px',
            height: '24px',
            marginLeft: '10px',
            color: '#8f8fae',
            backgroundColor: '#283270',
            padding: '1px',
          }}
          src="/assets/images/BellIcon2.png"
        ></Avatar> */}
        <Avatar sx={{ width: 24, height: 24, marginLeft: '10px', color: '#8f8fae', backgroundColor: 'white' }}>
          <Typography variant="body1">{stringToAvatar(fullName)}</Typography>
        </Avatar>
        <ListItemText primary={fullName} sx={{ marginLeft: '10px', fontSize: '12px', fontFamily: 'Roboto-Regular' }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleOnClose}
        onClick={handleOnClose}
      >
        {children}
        <MenuItem onClick={onCloseSession}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Close session
        </MenuItem>
      </Menu>
    </>
  );
};

AppUserMenu.propTypes = {
  fullName: string.isRequired,
  children: ChildrenPropType,
  onCloseSession: func,
};

export default AppUserMenu;
