import { useReducer, useCallback, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import useEnv from './useEnv';

const ACTION_STATUS = 'status';
const ACTION_ERROR = 'error';

const STATUS_SLEPPING = 'slepping';
const STATUS_RUNNING = 'running';
const STATUS_FINISHED = 'finished';

const reducer = (state, action) => {
  const { type, status, error } = action;

  switch (type) {
    case ACTION_STATUS:
      return { ...state, status };
    case ACTION_ERROR:
      return { ...state, error, status: STATUS_FINISHED };
    default:
      return state;
  }
};

const useBootstrapper = (config) => {
  const bootstraps = useRef(config.bootstraps);
  const [state, dispatch] = useReducer(reducer, { status: STATUS_SLEPPING, error: null });
  const { authState, oktaAuth } = useOktaAuth();
  const env = useEnv();

  const bootstrap = useCallback(async () => {
    try {
      if (state.status === STATUS_SLEPPING) {
        dispatch({ type: ACTION_STATUS, status: STATUS_RUNNING });
        await (() =>
          bootstraps.current
            .filter((initalBootstrap) => initalBootstrap.type === 'serial')
            .reduce(async (current, next) => {
              await current;
              return next.exec({ env, authState, oktaAuth });
            }, Promise.resolve()))();
        await Promise.all(
          bootstraps.current
            .filter((initalBootstrap) => initalBootstrap.type === 'paralell')
            .map((initalBootstrap) => initalBootstrap.exec({ env, authState, oktaAuth })),
        );
        dispatch({ type: ACTION_STATUS, status: STATUS_FINISHED });
      }
    } catch (error) {
      dispatch({ type: ACTION_ERROR, error });
    }
  }, [state, env, authState, oktaAuth]);

  return [bootstrap, state.status, state.error];
};

export default useBootstrapper;
