import React from 'react';
import { string, func } from 'prop-types';
import { Box } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import AppHeader from '../app-header';
import AppNav from '../app-nav';
import AppContent from '../app-content';
import AppFooter from '../app-footer';

const AppLayout = ({ children, title, Header, Nav }) => (
  <div style={{height: '100%', width: '100%'}}>
    <div id='divHeader' style={{float: 'left', height: '65px', width: '100%', top:"0px", position: "fixed"}}>
      {(Header && Header()) || <AppHeader />}
    </div>
    <div id='divContent' style={{float: 'left', height: 'calc(100% - 65px)', width: '100%', marginTop:'65px'}}>
      <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
        {(Nav && Nav({ title })) || <AppNav title={title} />}
        <AppContent>{children}</AppContent>
        <AppFooter />
      </Box>
    </div>
  </div>
);

AppLayout.propTypes = {
  title: string.isRequired,
  Header: func,
  Nav: func,
  children: ChildrenPropType,
};

export default AppLayout;
