import React from 'react';
import { Box } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const AppPage = ({ children }) => (
  <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>{children}</Box>
);

AppPage.propTypes = {
  children: ChildrenPropType,
};

export default AppPage;
