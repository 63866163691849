/* eslint-disable no-console */
const buildMessage = (message) => message;

const info = (message, ...params) => {
  console.info(buildMessage(message), ...params);
};

const warn = (message, ...params) => {
  console.warn(buildMessage(message), ...params);
};

const error = (message, ...params) => {
  console.error(buildMessage(message), ...params);
};

const Logger = { info, warn, error };

export default Logger;
