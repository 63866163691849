import React from 'react';
import { func, oneOf } from 'prop-types';
import { Drawer } from '@mui/material';
import { IssuePropType, ContributorPropType, Constants } from '@inner-source/cis-shared/types';
import { IssuePanelHeader, IssuePanelInfo } from './components';

const IssuePanel = ({ issue, contributor, variant, onClose }) => (
  <Drawer
    anchor="right"
    open={Boolean(issue)}
    variant="temporary"
    sx={{
      width: '400px',
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: '400px',
        boxSizing: 'border-box',
      },
    }}
    onClose={onClose}
  >
    <IssuePanelHeader onClose={onClose} />
    <IssuePanelInfo issue={issue} contributor={contributor} variant={variant} />
  </Drawer>
);

IssuePanel.propTypes = {
  issue: IssuePropType,
  contributor: ContributorPropType,
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]),
  onClose: func.isRequired,
};

export default IssuePanel;
