import React from 'react';
import { MenuOpen } from '@mui/icons-material';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { useEnv } from '@inner-source/cis-shared/hooks';
import './AppHeader.css';

const AppHeader = ({ children }) => {
  const { isMobileView, isMenuOpen, navSize, toggleMenu } = useEnv();
  return (
    <AppBar
      position="sticky"
      sx={{
       
        height: '65px',
        
        background: { paper: '#283270' },
        backgroundColor: '#283270'
      }}
    >
      <Toolbar>
        {/* {!isMenuOpen && (
          <IconButton color="inherit" onClick={toggleMenu}>
            <MenuOpen />
          </IconButton>
        )} */}
        {children}
      </Toolbar>
    </AppBar>
  );
};

AppHeader.propTypes = {
  children: ChildrenPropType,
};

export default AppHeader;
