import { httpTransportInit, bearerAuthStrategy } from '@inner-source/cis-shared/transports';

const initializeHttp = async ({ env: { getEnv }, authState }) => {
  if (authState) {
    const options = {
      baseUrl: getEnv('apiBaseUrl', 'http://localhost:3000'),
      authStrategy: bearerAuthStrategy(authState.accessToken.accessToken),
    };
    httpTransportInit(options);
  }
};

export default initializeHttp;
