import { shape, string, arrayOf, number } from 'prop-types';

const RepoPropType = shape({
  languages: arrayOf(string),
  url: string,
  commitCount: number,
  description: string,
  name: string,
  shortName: string,
  id: string,
});

export default RepoPropType;
