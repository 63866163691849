import React, { useCallback } from 'react';
import { AppLayout, AppNav } from '@inner-source/cis-shared/components';
import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { PortalHeader, PortalNavMenu } from './components';

const PortalLayout = ({ children }) => {
  const headerRenderProp = useCallback(() => <PortalHeader />, []);
  const navRenderProp = useCallback(
    ({ title }) => (
      <AppNav title={title}>
        <PortalNavMenu />
      </AppNav>
    ),
    [],
  );

  return (
    <AppLayout title="InnerSource" Header={headerRenderProp} Nav={navRenderProp}>
      {children}
    </AppLayout>
  );
};

PortalLayout.propTypes = {
  children: ChildrenPropType,
};

export default PortalLayout;
