import React from 'react';
import { List, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const LearningList = ({ pages }) => {
  return (
    <List>
      {pages &&
        pages.map((page) => (
          <ListItemButton button component="a" href={page.pageUrl} target="_blank">
            <ListItemAvatar>
              <SchoolOutlinedIcon />
            </ListItemAvatar>
            <ListItemText primary={page.title} />
          </ListItemButton>
        ))}
    </List>
  );
};

export default LearningList;
