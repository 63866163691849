import React, { useCallback, useEffect } from 'react';
import { func } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
  Snackbar,
  Slide,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { ContributorPropType, IssuePropType } from '@inner-source/cis-shared/types';
import { postSendStar } from '@inner-source/cis-shared/services';
import { useEnv, useAsyncFunc } from '@inner-source/cis-shared/hooks';
import MuiAlert from '@mui/material/Alert';

const starMeUpUrl = process.env.NX_STARMEUP_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContributorSendStarDialog = ({ contributor, issue, onClose, onSendStar }) => {
  const [notificationEnable, setNotificationEnable] = React.useState(false);
  const [notificationMsg, setNotificationMsg] = React.useState('');
  const [notificationButton, setNotificationButton] = React.useState(false);
  const [notificationUrl, setNotificationUrl] = React.useState(starMeUpUrl);
  const [notificationTime, setNotificationTime] = React.useState(2500);
  const [notificationSeverity, setNotificationSeverity] = React.useState('success');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [doPostSendStar, loading, error] = useAsyncFunc(postSendStar);
  const { user } = useEnv();

  const handleOnSubmit = useCallback(
    async ({ message }) => {
      const { email: from } = user;
      const { id } = issue;
      const { email: to } = contributor;
      const updatedIssue = await doPostSendStar({ id, from, to, message });
      if (updatedIssue) {
        setNotificationMsg('Your star was sent!');
        setNotificationSeverity('success');
        setNotificationButton(true);
        setNotificationUrl(starMeUpUrl + '/me/activity/' + updatedIssue.starId);
        setNotificationTime(5000);

        setNotificationEnable(true);
        onSendStar({ result: updatedIssue });
        onClose();
      }
    },
    [contributor, issue, user, doPostSendStar, onSendStar, onClose],
  );

  const handleNotifClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotificationEnable(false);
  };

  useEffect(() => {
    if (error && error.response.data.errorMessage) {
      setNotificationMsg(error.response.data.errorMessage);
      setNotificationSeverity('warning');
      setNotificationButton(false);
      setNotificationTime(5000);

      setNotificationEnable(true);
    }
  }, [error]);

  return (
    <Dialog open={Boolean(contributor)} fullScreen={fullScreen} onClose={onClose} keepMounted={false}>
      <Snackbar
        open={notificationEnable}
        autoHideDuration={notificationTime}
        onClose={handleNotifClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Slide}
      >
        <Alert severity={notificationSeverity} sx={{ width: '100%' }}>
          {notificationMsg}
          {notificationButton && (
            <Button color="inherit" size="small" href={notificationUrl} target="_blank" sx={{ height: '20px' }}>
              VIEW HERE!
            </Button>
          )}
        </Alert>
      </Snackbar>
      <DialogTitle>Say Thanks!</DialogTitle>
      <Formik
        initialValues={{ message: '' }}
        isInitialValid={false}
        validationSchema={object().shape({ message: string().required('Message is required') })}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, touched, isValid, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                {`Send a Star to ${
                  contributor && contributor.name
                } and tell us the reason you are recognizing him/her.`}
              </DialogContentText>
              <TextField
                autoFocus
                multiline
                rows={3}
                margin="normal"
                fullWidth
                name="message"
                value={values.message}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                variant="outlined"
                required
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton type="submit" loading={loading} disabled={!isValid}>
                Send Star
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

ContributorSendStarDialog.propTypes = {
  contributor: ContributorPropType,
  issue: IssuePropType,
  onClose: func.isRequired,
  onSendStar: func.isRequired,
};

export default ContributorSendStarDialog;
