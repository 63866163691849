import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, Divider, Avatar, CardContent, CardActions, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { AccountTree } from '@mui/icons-material';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import { getProjectsWithActiveIsssues } from '@inner-source/cis-shared/services';
import { ErrorMessage, NoData } from '@inner-source/cis-shared/components';

import { ProjectList } from '../../../../components';
import { ProjectsToContributeSkeleton } from './components';

const ProjectsToContribute = () => {
  const [projects, setProjects] = useState();
  const [doGetProjects, loading, error] = useAsyncFunc(
    useCallback(async () => {
      if (!projects) {
        const response = await getProjectsWithActiveIsssues({ limit: 5 });
        setProjects(response && response.projects);
      }
    }, [projects]),
  );

  useEffect(() => {
    doGetProjects();
  }, [doGetProjects]);

  return (
    <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '685px' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
            <AccountTree />
          </Avatar>
        }
        title={<Typography variant="h6">Projects Seeking Contribution</Typography>}
      />
      <Divider />
      <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        {error && <ErrorMessage onRetry={doGetProjects} />}
        {!error && loading && <ProjectsToContributeSkeleton />}
        {!error && !loading && projects && projects.length === 0 && <NoData />}
        {!error && !loading && projects && projects.length > 0 && (
          <ProjectList projects={projects} onRetry={doGetProjects} />
        )}
      </CardContent>
      <CardActions sx={{ pl: 3, display: 'flex' }}>
        <Button variant="text" color="primary" component={Link} to="/projects">
          See all
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectsToContribute;
