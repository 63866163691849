import React from 'react';
import { string, func } from 'prop-types';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import MenuOpen from '@mui/icons-material/MenuOpen';
import AppTitle from '../app-title';

const AppNavHeader = ({ title, onClose }) => (
  <AppBar position="relative">
    <Toolbar sx={{ bgcolor: 'primary.dark', justifyContent: 'space-between' }}>
      <AppTitle>{title}</AppTitle>
      {/* <IconButton color="inherit" onClick={onClose}>
        <MenuOpen />
      </IconButton> */}
    </Toolbar>
  </AppBar>
);

AppNavHeader.propTypes = {
  title: string.isRequired,
  onClose: func.isRequired,
};

export default AppNavHeader;
