import { shape, string, number } from 'prop-types';

const LeaderboardPropType = shape({
  id: string,
  type: string.isRequired,
  position: number.isRequired,
  starsCount: number.isRequired,
  fullName: string.isRequired,
  profileImageUrl: string.isRequired,
  profileUrl: string.isRequired,
});

export default LeaderboardPropType;
