import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ChildrenPropType } from '@inner-source/cis-shared/types';

const SecurityRender = ({ children }) => {
  const { authState } = useOktaAuth();
  return (
    <>
      {!authState && null}
      {authState && children}
    </>
  );
};

SecurityRender.propTypes = {
  children: ChildrenPropType,
};

export default SecurityRender;
