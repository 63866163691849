import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Chip } from '@mui/material';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { RepoPropType } from '@inner-source/cis-shared/types';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const RepoListItem = ({ repo }) => (
  <ListItem>
    <ListItemAvatar>
      <DeveloperModeIcon />
    </ListItemAvatar>
    <ListItemText primary={repo.name} secondary={repo.description} />
    <ListItemText sx={{ flex: 'initial', pl: 1, pr: 1 }}>
      {Array.isArray(repo.languages) &&
        repo.languages.map((language) => <Chip key={language} label={language} size="small" sx={{ mr: 1 }} />)}
    </ListItemText>
    <ListItemText sx={{ flex: 'initial', pl: 1, pr: 1 }}>
      <IconButton color="primary" aria-label="Search" component="a" href={repo.url} target="_blank">
        <SearchIcon />
      </IconButton>
    </ListItemText>
  </ListItem>
);
RepoListItem.propTypes = {
  repo: RepoPropType.isRequired,
};

export default RepoListItem;
