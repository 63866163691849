import { httpPost } from '@inner-source/cis-shared/transports';

const sendEmail = async ({ toAddress, fromAddress, ccAddress, name, issue, id, formLink }) =>
  httpPost('https://mh2ghh9hdj.execute-api.us-east-1.amazonaws.com/Stage/send-email', {
    toAddress,
    fromAddress,
    ccAddress,
    name,
    issue,
    id,
    formLink,
  });

export default sendEmail;
