import { shape, string, arrayOf } from 'prop-types';
import { JiraAvatarUrlsPropType } from './jira-prop-types';

const ProjectPropType = shape({
  languages: arrayOf(string),
  avatarUrls: JiraAvatarUrlsPropType.isRequired,
  name: string.isRequired,
  id: string.isRequired,
  projectTypeKey: string.isRequired,
  updated: string,
  key: string.isRequired,
  description: string,
});

export default ProjectPropType;
