import React from 'react';
import { oneOf } from 'prop-types';
import { TableRow, TableCell } from '@mui/material';
import { Constants } from '@inner-source/cis-shared/types';

const IssueTableHead = ({ variant }) => (
  <TableRow>
    <TableCell>Type</TableCell>
    <TableCell>Project Name</TableCell>
    {/* {variant === Constants.ISSUE_STATE_ACTIVE && <TableCell>Creator</TableCell>} */}
    {variant === Constants.ISSUE_STATE_DONE && <TableCell>Contributor</TableCell>}
    <TableCell>Created On</TableCell>
    <TableCell>Source Code</TableCell>
    <TableCell>Contribution Guide</TableCell>
    {/* {variant === Constants.ISSUE_STATE_ACTIVE && <TableCell>Timeframe</TableCell>} */}
    <TableCell />
  </TableRow>
);

IssueTableHead.propTypes = {
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]).isRequired,
};

export default IssueTableHead;
