import { shape, string } from 'prop-types';
import { JiraPriorityPropType, JiraStatusPropType, JiraTypePropType, JiraUserPropType } from './jira-prop-types';
import ProjectPropType from './project-prop-type';

const IssuePropType = shape({
  summary: string.isRequired,
  created: string.isRequired,
  duedate: string,
  project: ProjectPropType.isRequired,
  id: string.isRequired,
  priority: JiraPriorityPropType.isRequired,
  projectId: string.isRequired,
  updated: string,
  status: JiraStatusPropType.isRequired,
  type: JiraTypePropType.isRequired,
  creator: JiraUserPropType.isRequired,
});

export default IssuePropType;
