/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Grid, Stack, Link, Typography, Divider } from '@mui/material';
import { useEnv } from '@inner-source/cis-shared/hooks';

const starMeUpUrl = process.env.NX_STARMEUP_URL;
const stackOverFlowUrl = process.env.NX_STACKOVERFLOW_URL;

const Footer = () => {
  const { isMenuOpen, navSize } = useEnv();
  return (
    <footer
      style={{
        display: 'flex',
        flex: '1 1 100%',


        maxHeight: '90px',
      }}
    >
      <Grid container sx={{ backgroundColor: "#E0E0E0", paddingTop: '8px' }}>
        {/*  <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              <Link
                href={stackOverFlowUrl}
                target="_blank"
                rel="noopener noreferrer"
                title="StackOverFlow for Teams"
                sx={{ fontWeight: 500 }}
              >
                Ask for help
              </Link>
            </Typography>
          </Stack>
        </Grid> */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', }}>
          <Typography variant="caption">&nbsp;&nbsp;&nbsp;&copy; Carrier. All Rights Reserved.</Typography>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: 'flex' }} justifyContent="center">
          <Typography variant="caption">Contact Us :
            <Link href="mailto:admin.innersource@carrier.com" underline="hover">
              admin.innersource@carrier.com
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{ display: 'flex', }} justifyContent="flex-end" spacing={2}>
          <Typography variant="caption">
            <Link href="#" underline="hover" sx={{ mr: '15px' }}>
              Contact Us
            </Link>
            <Link href="#" underline="hover" sx={{ mr: '15px' }}>
              Help
            </Link>
            <Link href="#" underline="hover" sx={{ mr: '5px' }}>
              Terms
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
