/* eslint-disable react/no-danger */
import React from 'react';
import { oneOf } from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, Box, Stack, Button, Link, Typography } from '@mui/material';
import { Title, Person, Event, ImportExport, Task, Description, VpnKey } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { IssuePropType, ContributorPropType, Constants } from '@inner-source/cis-shared/types';
import { FormatDate } from '@inner-source/cis-shared/components';

const IssuePanelInfo = ({ issue, contributor, variant }) => (
  <Stack spacing={0} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
    <List>
      <ListItem>
        <ListItemIcon>
          <Title />
        </ListItemIcon>
        <ListItemText
          primary={issue && issue.summary}
          primaryTypographyProps={{ sx: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
          secondary="Project Name"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary={issue && issue['Project Owner Email']} secondary="Project Owner" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Event />
        </ListItemIcon>
        <ListItemText primary={<FormatDate date={issue && issue.created} />} secondary="Created On" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              noWrap
              variant="body2"
              component={Link}
              href={issue && issue['Sourcegraph URL'] ? issue['Sourcegraph URL'] : ''}
              target="_blank"
              sx={{ display: 'block', cursor: 'pointer' }}
            >
              View Source Code
            </Typography>
          }
          secondary="Source Code"
        />
      </ListItem>{' '}
      <ListItem>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              noWrap
              variant="body2"
              component={Link}
              href={issue && issue['Contribution Guide'] ? issue['Contribution Guide'] : ''}
              target="_blank"
              sx={{ display: 'block', cursor: 'pointer' }}
            >
              View Contribution Guide
            </Typography>
          }
          secondary="Contribution Guide"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText
          primary="Description"
          secondary={
            <Box sx={{ pl: 2, pr: 2, overflow: 'hidden', overflowWrap: 'break-word' }}>
              <div dangerouslySetInnerHTML={{ __html: issue && issue.description }} />
            </Box>
          }
        />
      </ListItem>
    </List>
  </Stack>
);

IssuePanelInfo.propTypes = {
  issue: IssuePropType,
  contributor: ContributorPropType,
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]),
};

export default IssuePanelInfo;
