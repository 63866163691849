/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Button,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const Metrics = () => (
  <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
          <AssessmentIcon />
        </Avatar>
      }
      title={<Typography variant="h6">Metrics</Typography>}
    />
    <Divider />
    <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1 }}>
      <List>
        <ListItemButton>
          <ListItemText primary="Portal Viewers" secondary="Total number of unique Portal viewers" />
          <TrendingUpIcon />
          <ListItemText sx={{ color: blue[500], textAlign: 'right' }}>
            <h2> N/A </h2>
          </ListItemText>
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemText primary="Collaborators" secondary="People collaborating among projects" />
          <TrendingFlatIcon />
          <ListItemText sx={{ color: blue[500], textAlign: 'right' }}>
            <h2> N/A </h2>
          </ListItemText>
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemText primary="Monthly Collaborations" secondary="Collaborations completed this month" />
          <TrendingDownIcon />
          <ListItemText sx={{ color: blue[500], textAlign: 'right' }}>
            <h2> N/A </h2>
          </ListItemText>
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary="Monthly Recognitions" secondary="Total StarMeUp stars sent this month" />
          <TrendingFlatIcon />
          <ListItemText sx={{ color: blue[500], textAlign: 'right' }}>
            <h2> N/A </h2>
          </ListItemText>
        </ListItemButton>
      </List>
    </CardContent>
    <Divider />
    <CardActions sx={{ pl: 3, display: 'flex' }}>
      <Button variant="text" color="primary">
        See all
      </Button>
    </CardActions>
  </Card>
);

export default Metrics;
