import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { arrayOf, func } from 'prop-types';
import { motion } from 'framer-motion';

import { ChildrenPropType } from '@inner-source/cis-shared/types';
import { useBootstrapper } from '@inner-source/cis-shared/hooks';

const AppBootstrapper = ({ children, Logo, serial = [], paralell = [] }) => {
  const [bootstrap, status] = useBootstrapper({
    bootstraps: [
      ...serial.map((exec) => ({ type: 'serial', exec })),
      ...paralell.map((exec) => ({ type: 'paralell', exec })),
    ],
  });

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);

  return (
    <>
      {status === 'finished' ? (
        children
      ) : (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          role="alert"
          aria-busy="true"
        >
          <motion.div
            initial={{ opacity: 0.7, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.75, repeat: Infinity }}
          >
            <Logo />
          </motion.div>
        </Box>
      )}
    </>
  );
};

AppBootstrapper.propTypes = {
  children: ChildrenPropType,
  Logo: func,
  serial: arrayOf(func),
  paralell: arrayOf(func),
};

export default AppBootstrapper;
