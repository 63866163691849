import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ProjectToContributeSkeleton = () => (
  <Box>
    <Skeleton sx={{ height: '56px' }} />
    <Skeleton sx={{ height: '56px' }} />
    <Skeleton sx={{ height: '56px' }} />
    <Skeleton sx={{ height: '56px' }} />
    <Skeleton sx={{ height: '56px' }} />
  </Box>
);

export default ProjectToContributeSkeleton;
