import React, { useCallback, useEffect, useState } from 'react';
import { func, bool, oneOf } from 'prop-types';
import { ListItemButton, ListItemAvatar, ListItemText, Avatar, Collapse, Chip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProjectPropType, Constants } from '@inner-source/cis-shared/types';
import { getIssuesByProject, getJiraData } from '@inner-source/cis-shared/services';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import IssueTable from '../../../issue-table';

const jiraBaseUrl = process.env.NX_JIRA_URL;

function useUpdateIssues(issues, allJiraData) {
  return React.useMemo(() => {
    if (!issues || !allJiraData) {
      return issues;
    }
    return updateIssueObject(issues, allJiraData);
  }, [issues, allJiraData]);
}

function updateIssueObject(issueObjects, detailedObject) {
  if (!issueObjects || !detailedObject) {
    return issueObjects;
  }
  // Loop through all parent objects
  return issueObjects.map((issueObject) => {
    const issueKey = issueObject.key;

    // Find child object containing the issue key
    const detailedInfo = Object.values(detailedObject).find(
      (childObject) => childObject && childObject.hasOwnProperty(issueKey),
    );

    // Check if detailedInfo is an object
    if (
      detailedInfo &&
      typeof detailedInfo === 'object' &&
      detailedInfo[issueKey] &&
      typeof detailedInfo[issueKey] === 'object'
    ) {
      return {
        ...issueObject, // Spread existing properties
        'Project Owner Email': detailedInfo[issueKey]['Project Owner Email'] || '',
        'Sourcegraph URL': detailedInfo[issueKey]['Sourcegraph URL'] || '',
        'Contribution Guide': detailedInfo[issueKey]['Contribution Guide'] || '',
        'Form Link': detailedInfo[issueKey]['Form Link'] || '',
        // Add logic for other properties
      };
    }
    // Return original object if no match or invalid data
    return issueObject;
  });
}

const ProjectListItem = ({ open, project, variant, onClick }) => {
  const [issues, setIssues] = useState();
  const [getIssuesData, setGetIssuesData] = useState([]);
  const [allJiraData, setJiraData] = useState([]);

  const [doGetJiraData, runGetJiraData, errorGetJiraData] = useAsyncFunc(getJiraData);

  const issuesOfProjectObject = useUpdateIssues(issues, allJiraData);
  const memoizedJiraData = React.useMemo(() => {
    return doGetJiraData({ label: 'helpwanted' }); // Assuming doGetJiraData returns a Promise
  }, [doGetJiraData]); // Re-compute only if doGetJiraData changes

  useEffect(() => {
    if (issuesOfProjectObject) {
      setGetIssuesData(issuesOfProjectObject);
    }
  }, [issuesOfProjectObject, getIssuesData]);

  const [doGetIssuesByProject, loading] = useAsyncFunc(
    useCallback(async () => {
      const { id: projectId } = project;
      const JiraData = await memoizedJiraData;
      setJiraData(JiraData);
      console.log('JiraData', JiraData);
      if (!issues) {
        const { issues: issuesOfProject } = await getIssuesByProject({ projectId, state: variant });
        setIssues(issuesOfProject);
      }
    }, [project, doGetJiraData, issues, variant]),
  );

  const handleOnClick = useCallback(
    (event) => {
      onClick(event, project);
      doGetIssuesByProject();
    },
    [doGetIssuesByProject, project, onClick],
  );

  const handleOnChange = useCallback((changedIssue) => {
    if (changedIssue) {
      setIssues((currentIssues) => currentIssues.map((issue) => (issue.id !== changedIssue.id ? issue : changedIssue)));
    }
  }, []);

  return (
    <>
      <ListItemButton onClick={handleOnClick} selected={open}>
        <ListItemAvatar>
          <Avatar src={project.avatarUrls['32x32']} />
        </ListItemAvatar>
        <ListItemText primary={project.name} secondary={project.description} />
        <ListItemText sx={{ flex: 'initial', pl: 1, pr: 1 }}>
          {Array.isArray(project.languages) &&
            project.languages.map((language) => <Chip label={language} size="small" sx={{ mr: 1 }} />)}
        </ListItemText>

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <IssueTable issues={getIssuesData} loading={loading} variant={variant} onChange={handleOnChange} />
      </Collapse>
    </>
  );
};

ProjectListItem.propTypes = {
  open: bool,
  project: ProjectPropType.isRequired,
  variant: oneOf([Constants.ISSUE_STATE_ACTIVE, Constants.ISSUE_STATE_DONE]).isRequired,
  onClick: func.isRequired,
};

export default ProjectListItem;
