import React, { useState, useCallback, useEffect } from 'react';
import { Box, Card, CardHeader, CardContent, Avatar, Typography, Divider, Tabs, Tab } from '@mui/material';
import { blue } from '@mui/material/colors';
import { AccountTree } from '@mui/icons-material';
import { AppPage, TabPanel } from '@inner-source/cis-shared/components';
import { useAsyncFunc } from '@inner-source/cis-shared/hooks';
import { getProjectsWithActiveIsssues, getProjectsWithDoneIsssues } from '@inner-source/cis-shared/services';
import { Constants } from '@inner-source/cis-shared/types';

import { ProjectList } from '../../components';

const Projects = () => {
  const [value, setValue] = useState(0);
  const [projectsOpenForContributions, setProjectsOpenForContributions] = useState();
  const [projectsContributed, setProjectsContributed] = useState();
  const [doGetProjectsWithActiveIsssues, loadingGetProjectsWithActiveIsssues, errorGetProjectsWithActiveIsssues] =
    useAsyncFunc(getProjectsWithActiveIsssues);

  const [doGetProjectsWithDoneIsssues, loadingGetProjectsWithDoneIsssues, errorGetProjectsWithDoneIsssues] =
    useAsyncFunc(getProjectsWithDoneIsssues);

  const doGetProjectsForOpenContributions = useCallback(async () => {
    const response = await doGetProjectsWithActiveIsssues({ limit: 50 });
    setProjectsOpenForContributions(response.projects);
  }, [doGetProjectsWithActiveIsssues]);

  const doGetProjectsContributed = useCallback(async () => {
    const response = await doGetProjectsWithDoneIsssues({ limit: 50 });
    setProjectsContributed(response.projects);
  }, [doGetProjectsWithDoneIsssues]);

  const handleOnChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    doGetProjectsForOpenContributions();
    doGetProjectsContributed();
  }, [doGetProjectsForOpenContributions, doGetProjectsContributed]);

  return (
    <AppPage>
      <Card variant="outlined" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '685px' }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'rgb(1,112,192)' }}>
              <AccountTree />
            </Avatar>
          }
          title={<Typography variant="h6">Projects Seeking Contribution</Typography>}
        />
        <Divider />
        <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} variant="fullWidth" onChange={handleOnChange}>
              <Tab label="Open for Contributions" />
              <Tab label="Contributed" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ProjectList
              loading={loadingGetProjectsWithActiveIsssues}
              error={Boolean(errorGetProjectsWithActiveIsssues)}
              projects={projectsOpenForContributions}
              onRetry={doGetProjectsForOpenContributions}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProjectList
              loading={loadingGetProjectsWithDoneIsssues}
              error={Boolean(errorGetProjectsWithDoneIsssues)}
              projects={projectsContributed}
              variant={Constants.ISSUE_STATE_DONE}
              onRetry={doGetProjectsContributed}
            />
          </TabPanel>
        </CardContent>
      </Card>
    </AppPage>
  );
};

export default Projects;
