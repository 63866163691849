import { shape, string, number, bool } from 'prop-types';

export const JiraAvatarUrlsPropType = shape({
  '48x48': string,
  '32x32': string,
  '24x24': string,
  '16x16': string,
});

export const JiraUserPropType = shape({
  accountId: string.isRequired,
  emailAddress: string.isRequired,
  avatarUrls: JiraAvatarUrlsPropType,
  displayName: string.isRequired,
  accountType: string,
  self: string.isRequired,
  active: bool,
  timeZone: string,
});

export const JiraPriorityPropType = shape({
  name: string.isRequired,
  self: string.isRequired,
  iconUrl: string,
  id: string.isRequired,
});

export const JiraStatusCategoryPropType = {
  name: string.isRequired,
  self: string.isRequired,
  colorName: string,
  id: string.isRequired,
  key: string.isRequired,
};

export const JiraStatusPropType = shape({
  name: string.isRequired,
  self: string.isRequired,
  description: string,
  iconUrl: string,
  id: string.isRequired,
  statusCategory: JiraStatusCategoryPropType.isRequired,
});

export const JiraTypePropType = shape({
  avatarId: number,
  hierarchyLevel: number,
  name: string.isRequired,
  self: string.isRequired,
  description: string,
  entityId: string,
  id: string.isRequired,
  iconUrl: string,
  subtask: bool,
});
